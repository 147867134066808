.mainProjectContainer {
  padding: 1rem 1rem 2rem 4rem;
  /* background-color: aquamarine; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  align-items: flex-start;
  /* color: black; */
}
@media (max-width: 200px) {
  .mainProjectContainer {
    padding-left: 1rem;
  }
}
@media (max-width: 300px) {
  .mainProjectContainer {
    padding-left: 1.5rem;
  }
}
@media (max-width: 480px) {
  .mainProjectContainer {
    padding-left: 2rem;
  }
}

div.project > p {
  margin-bottom: 0px;
}
.proBox {
  height: 100%;
  /* background-color: moccasin; */
  display: grid;
  grid-template-rows: 1fr auto;
  /* flex-direction: row; */
  row-gap: 0.5em;
  justify-content: start;
  align-content: flex-start;
  justify-items: start;
  /* flex-wrap: wrap; */
}
@media (min-width: 820px) {
  .proBox {
    grid-template-columns: auto auto;
    column-gap: 0.5em;
    justify-content: start;
  }
  .proAllList {
    min-width: 15rem;
    max-width: 50rem;
  }
  .proDesc {
    min-width: 30rem;
  }
}
.proAllList {
  justify-self: flex-start;
  width: 100%;
  max-width: 500px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-items: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.proListItem {
  /* background-color: mediumseagreen; */
  display: flex;
  align-self: stretch;
  /* grid-template-columns: auto 1fr; */
  flex-direction: row;
  justify-content: space-between;

  margin-top: 0.2rem;
  /* flex-wrap: nowrap; */
  border: none;
  border-radius: 1%;
}
/* .proItemGap {
  align-self: flex-start;
} */
.proDesc {
  width: 100%;

  height: 100%;
  background-color: #2a2a2a;
  border: 0.1em solid #384243;
}
