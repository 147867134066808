.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* /----------------------------------------- */
body {
}
.main {
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: #1d1d20;
  color: #cccccc;
  font-family: Montserrat-Regular;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr calc(93%);
  grid-auto-rows: minmax(5rem, auto);
  align-content: flex-start;
  font-size: 0.9em;
  font-weight: 100;
  /* line-height: 1.6px; */
}
header {
}
article {
  /* padding: 0px 0px 0px 0px; */
  height: max-content;
  background-color: #1d1d20;
}
footer {
  background-color: #1d1d20;
}
.articleBox {
  justify-self: center;
  padding-top: 1rem;
  padding-left: 3rem;
}
.myNav {
  /* background-color: #3f3f46; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-items: left;
  min-height: 50px;
}
.navBrand {
  width: 30%;
}
.navLinks {
  display: flex;
  width: auto;
  justify-content: space-between;
  justify-self: flex-end;
}
.navLink {
  border: none;
  margin-right: 15px;
  background-color: transparent;
  color: #cccccc;
  text-justify: auto;
}
@media (min-width: 529px) {
  .navLinkIcon {
    display: none;
  }
}

@media (max-width: 529px) {
  .navLinkText {
    display: none;
  }
  .navLinkIcon {
    display: contents;
    font-size: 1.2em;
  }
}

.navLinkActive {
  border-bottom: 0.1rem solid #cccccc;
}
.navLinkActive .navLinkIcon {
  font-size: 1.4em;
}
.profilePhotoDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}
.profilePhoto {
  max-width: 200px;
  width: 100%;
  border-radius: 50%;
  /* border: 10px solid white;
   */
  /* border-width: 5px;
  border-style: solid;
  border-image: radial-gradient(to right, red, rgba(0, 0, 0, 0)) 1 100%; */

  /* border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  background-image: linear-gradient(
    to bottom,
    rgb(9, 112, 26) 0%,
    rgb(21, 255, 0) 100%
  ); */
  /* border-image-source: radial-gradient(90deg, #743ad5, #d53a9d); */
  box-shadow: 0px 0px 2px 2px #cccccc;
}

.articleContent {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
.aboutInfo {
  /* border-left-color: rgb(213, 218, 27); */
  width: auto;
  max-width: 720px;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%;
  border-right: none;

  padding-left: 1rem;
  margin-top: 20px;

  font-size: large;
}
.socialLinks {
  height: 2rem;
}
.iconsSocialLinks {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  font-size: 1.3em;
  color: #cccccc;

  text-align: center;
  transition-property: all;
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;
}
.iconsSocialLinks:hover {
  font-size: 2rem;
}
.artEx {
  margin-top: 10%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
}
@media (min-width: 540px) {
  .ExperienceMainContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 80%;
  }
}
.articleExperience {
  display: flex;
  padding-left: 4rem;
  padding-right: 1rem;
  justify-content: flex-start;
}
@media (max-width: 400px) {
  .articleExperience {
    padding-left: 1.2rem;
  }
}
.jouneyContainer {
  display: grid;
  grid-template-rows: 1fr calc(100%);
  row-gap: 0.3rem;
  /* grid-auto-flow: row; */
  justify-content: center;
  justify-items: center;
}
.role {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #cccccc;
}
.first {
  animation: dotGlow 1.5s ease-in-out 0s infinite;
}
@keyframes dotGlow {
  0% {
    box-shadow: 0px 0px 0px #cccccc;
  }
  100% {
    box-shadow: 0px 0px 10px 10px #cccccc;
  }
}
.journey {
  border-left: 3px solid white;
  align-self: stretch;
}

.journyInfo {
  margin-left: 0.7rem;
  align-items: start;
}

.journyInfo p {
  line-height: 0.8rem;
}
.jobRole span,
.comapanyName span {
  background-color: #cccccc;
  color: #1d1d20;
  border-radius: 0.2rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.comapanyName {
  font-size: 1.3em;
}
.last {
  visibility: hidden;
}
/* //---------------------------------------------------------------------------------- */
/* Skills Css */
