.skillBoxMain {
  background-color: #1d1d20;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: center;
  align-items: stretch;
  align-content: space-evenly;
  padding-left: 5%;
}
.skillBoxMain div {
  margin-top: 2%;
}

.skillBoxSub {
  margin-bottom: 1rem;
  /* background-color: aquamarine; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  grid-gap: 1rem;
  justify-content: space-evenly;
  justify-items: center;
}
@media (min-width: 200px) {
  .skillBoxSub {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 400px) {
  .skillBoxSub {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 700px) {
  .skillBoxSub {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1100px) {
  .skillBoxSub {
    grid-template-columns: repeat(8, 1fr);
  }
}
.skill {
  /* background-color: blueviolet; */
}

.skillName {
  /* background-color: burlywood; */
  color: #cccccc;
  text-align: center;
}

svg {
  height: 100;
  /* animation: rotate 6s linear infinite; */
}
.skill {
  background-color: #3f3f46;
  border-radius: 1rem;
}

/* @keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  51% {
    transform: rotateY(180deg);
  }

  99% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(0deg);
  }
} */
.svgImg {
  width: 100px;
  height: 100px;
}

.skillSetHeading {
  width: auto;
  padding: 0.08em 0.5em;
  font-size: 1.2em;
  color: #1d1d20;
  background-color: #cccccc;
  border-radius: 3px;
  font-weight: 700;
}
