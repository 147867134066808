.edMain {
  /* background-color: pink; */
  height: 100%;
  /* color: black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  align-items: flex-start;
  align-content: center;
  margin-left: 4rem;
  margin-right: 1rem;
}
@media (max-width: 450px) {
  .edMain {
    margin-left: 1.2rem;
  }
}
.heading {
  width: 100%;
  display: flex;
  /* grid-template-columns: repeat(20); */
  /* column-gap: 1rem; */
  /* background-color: aqua; */
  align-items: center;
}
.expHead {
  padding-left: 4rem;
  padding-right: 1rem;
}
@media (max-width: 500px) {
  .expHead {
    padding-left: 1rem;
  }
}

.dash {
  width: 90%;
  border-top: 0.2rem Solid #cccccc;
  margin-right: 10px;
  padding: 0px;
  margin: 0%;
}
.headingText {
  width: max-content;
  margin-right: 1rem;
}
.edContent {
  width: 100%;
  /* background-color: mediumpurple; */
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: 1fr 10fr; */
  /* align-content: space-evenly; */
  justify-content: start;

  /* align-items: center; */
  align-content: space-between;
  justify-items: start;
}

.collegeImg {
  background-color: darkolivegreen;
  width: 50px;
  height: 100%;
}
.collegeInfo {
  /* background-color: gray; */
  margin-left: 1rem;
  text-align: start;
}
.collegeInfo p {
  margin-bottom: 0%;
}
.collegeName span {
  background-color: #cccccc;
  color: #1d1d20;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
.edContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}
.edContentContainer div {
  margin-top: 1rem;
}

.collegeYear {
  /* font-weight: 300; */
  font-weight: lighter;
  opacity: 80%;
  font-size: 0.9em;
}
